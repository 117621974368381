import { invokeApi } from "../../bl_libs/invokeApi";
import { source_base_uri } from "../../config/config";

export const _upload_picture_to_user_gallery = (files) => {
  let requestObj = {
    path: "/api/customer/upload_picture_to_user_gallery",
    method: "POST",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
  };
  requestObj["postData"] = files;
  return invokeApi(requestObj);
};

export const _upload_image_from_url = (data) => {
  let requestObj = {
    path: "/api/app_api/upload_s3_image_from_url",
    method: "POST",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
  };
  requestObj["postData"] = data;
  return invokeApi(requestObj);
};

export const _add_picture_to_user_gallery = (data) => {
  let requestObj = {
    path: "/api/customer/add_picture_to_user_gallery",
    method: "POST",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
  };
  requestObj["postData"] = data;
  return invokeApi(requestObj);
};

export const get_user_gallery = () => {
  let requestObj = {
    path: "/api/customer/get_user_gallery",
    method: "GET",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const get_template_images = (data) => {
  let requestObj = {
    path: "/api/template/template_files",
    method: "POST",
    base_url: source_base_uri,
    postData: data,
  };
  return invokeApi(requestObj);
};
export const get_stock_images = () => {
  let requestObj = {
    path: "/api/images/get_stock_images_list",
    method: "GET",
    base_url: source_base_uri,
  };
  return invokeApi(requestObj);
};
export const delete_picture = (data) => {
  let requestObj = {
    path: "/api/customer/delete_picture",
    method: "POST",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
    postData: data,
  };

  return invokeApi(requestObj);
};

// Pexels API

export const pexels_pictures = (url, key) => {
  let requestObj = {
    completeUrl: url,
    method: "GET",
    headers: {
      Authorization: key,
    },
  };

  return invokeApi(requestObj);
};

export const get_user_pexels_saved_images = () => {
  let requestObj = {
    path: "/api/customer/list_picture_from_pexels_gallery",
    method: "GET",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const save_pexel_picture = (data) => {
  let requestObj = {
    path: "/api/customer/add_picture_to_pexels_gallery",
    method: "POST",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
    postData: data,
  };

  return invokeApi(requestObj);
};

export const _increase_pexel_request_count = () => {
  let requestObj = {
    path: "/api/customer/increase_pexel_request_count",
    method: "PUT",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};

// Videos

export const _upload_video_to_user_gallery = (files) => {
  let requestObj = {
    path: "/api/customer/upload_video_for_user_gallery",
    method: "POST",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
  };
  requestObj["postData"] = files;
  return invokeApi(requestObj);
};

export const delete_video = (data) => {
  let requestObj = {
    path: "/api/customer/delete_video_for_user_gallery",
    method: "POST",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
    postData: data,
  };

  return invokeApi(requestObj);
};

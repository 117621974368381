import { invokeApi } from "../../bl_libs/invokeApi";
import { vissioon_base_url } from "../../config/config";

export const _add_payment_plan_of_vissioon = (data) => {
  let requestObj = {
    path: "/api/pages_paymnet_plan/add_pages_payment_plan",
    method: "POST",
    base_url: vissioon_base_url,
    headers: {
      "x-sh-auth": sessionStorage.getItem("vissiion_api_token"),
    },
  };
  requestObj["postData"] = data;
  return invokeApi(requestObj);
};

export const _edit_payment_plan_of_vissioon = (data, _id) => {
  let requestObj = {
    path: "/api/pages_paymnet_plan/edit_pages_payment_plan/" + _id,
    method: "PUT",
    base_url: vissioon_base_url,
    headers: {
      "x-sh-auth": sessionStorage.getItem("vissiion_api_token"),
    },
  };
  requestObj["postData"] = data;
  return invokeApi(requestObj);
};

export const _get_payment_plan_detail_of_vissioon = (_id) => {
  let requestObj = {
    path: "/api/pages_paymnet_plan/get_pages_payment_plan_detail/" + _id,
    method: "GET",
    base_url: vissioon_base_url,
    headers: {
      "x-sh-auth": sessionStorage.getItem("vissiion_api_token"),
    },
  };
  return invokeApi(requestObj);
};

export const _delete_payment_plan_of_vissioon = (planId, coachId) => {
  let requestObj = {
    path: `/api/pages_paymnet_plan/delete_pages_payment_plan/${planId}/${coachId}`,
    method: "DELETE",
    base_url: vissioon_base_url,
    headers: {
      "x-sh-auth": sessionStorage.getItem("vissiion_api_token"),
    },
  };
  return invokeApi(requestObj);
};

export const _add_user_stripe_keys = (data) => {
  let requestObj = {
    path: "/api/project_configuration/update_stripe_keys",
    method: "PUT",
    base_url: vissioon_base_url,
    headers: {
      "x-sh-auth": sessionStorage.getItem("vissiion_api_token"),
    },
  };
  requestObj["postData"] = data;
  return invokeApi(requestObj);
};



export const currency_list_of_vissioon_coach = async (id) => {
  const requestObj = {
    path: `/api/app_setting/list_currency_delegate`,
    method: "GET",
    base_url: vissioon_base_url,
    headers: {
      "x-sh-auth": sessionStorage.getItem("vissiion_api_token"),
    },
  };
  return invokeApi(requestObj);
};



import { invokeApi } from "../../bl_libs/invokeApi";
import { admin_api_key } from "../../config/config";
export const _public_keys = async (data) => {
  let requestObj = {
    path: `/api/key/get_key`,
    method: "POST",
    headers: { "x-sh-auth": `${admin_api_key}` },
    postData: data,
  };
  return invokeApi(requestObj);
};

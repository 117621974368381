import { invokeApi } from "../../bl_libs/invokeApi";

export const list_all_plan_by_project_id = async (id) => {
  const requestObj = {
    path: `/api/plan/list_all_plan_by_project_id/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

import { invokeApi } from "../../bl_libs/invokeApi";

export const detail_customer_by_token = () => {
  let requestObj = {
    path: "/api/customer/detail_customer_by_token",
    method: "GET",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const edit_customer_over_view = (data) => {
  let requestObj = {
    path: `/api/customer/edit_over_view`,
    method: "PUT",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

import { source_base_uri } from "../../config/config";
import { invokeApi } from "../../bl_libs/invokeApi";

export const _templates_list = (postData) => {
  let requestObj = {
    path: "/api/template/list_template",
    method: "POST",
    base_url: source_base_uri,
    headers: {
      // "x-sh-auth": sessionStorage.getItem("token"),
      "x-sh-auth":
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzZWNyZXRfYXV0aF9rZXkiOiIxaTh3ZHM2ZGJkbnlkNmUmYmdlYmViYmUiLCJpYXQiOjE2ODg5Njc4ODV9.9hGtIrHK26AR8yZ4V5o09xi9vB95NZI07jzlx5F5qUo",
    },
    postData,
  };

  return invokeApi(requestObj);
};

export const _user_purchased_templates_list = (user_id) => {
  let requestObj = {
    path: "/api/user_template/list_user_templates/" + user_id,
    method: "GET",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};

export const _templates_usage_increase = (_id, data) => {
  let requestObj = {
    path: "/api/template/increase_usage_count/" + _id,
    method: "PUT",
    base_url: source_base_uri,
    headers: {
      // "x-sh-auth": sessionStorage.getItem("token"),
      "x-sh-auth":
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzZWNyZXRfYXV0aF9rZXkiOiIxaTh3ZHM2ZGJkbnlkNmUmYmdlYmViYmUiLCJpYXQiOjE2ODg5Njc4ODV9.9hGtIrHK26AR8yZ4V5o09xi9vB95NZI07jzlx5F5qUo",
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _page_category_list = () => {
  let requestObj = {
    path: "/api/template_category/list_template_category",
    method: "GET",
    base_url: source_base_uri,
    headers: {
      // "x-sh-auth": sessionStorage.getItem("token"),
      "x-sh-auth":
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzZWNyZXRfYXV0aF9rZXkiOiIxaTh3ZHM2ZGJkbnlkNmUmYmdlYmViYmUiLCJpYXQiOjE2ODg5Njc4ODV9.9hGtIrHK26AR8yZ4V5o09xi9vB95NZI07jzlx5F5qUo",
    },
  };

  return invokeApi(requestObj);
};

export const _sections_list = () => {
  let requestObj = {
    path: "/api/section/list_section",
    method: "GET",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};

export const _templates_category_list = () => {
  let requestObj = {
    path: "/api/category/list_category",
    method: "GET",
    base_url: source_base_uri,
    headers: {
      // "x-sh-auth": sessionStorage.getItem("token"),
      "x-sh-auth":
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzZWNyZXRfYXV0aF9rZXkiOiIxaTh3ZHM2ZGJkbnlkNmUmYmdlYmViYmUiLCJpYXQiOjE2ODg5Njc4ODV9.9hGtIrHK26AR8yZ4V5o09xi9vB95NZI07jzlx5F5qUo",
    },
  };

  return invokeApi(requestObj);
};

import { invokeApi } from "../../bl_libs/invokeApi";

export const _send_query_to_ai = (data) => {
  let requestObj = {
    path: "/api/app_api/get_gpt_content",
    method: "POST",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
  };

  requestObj["postData"] = data;
  return invokeApi(requestObj);
};


export const _send_logo_query_to_ai = (data) => {
  let requestObj = {
    path: "/api/app_api/generate_picture_with_gpt",
    method: "POST",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
  };

  requestObj["postData"] = data;
  return invokeApi(requestObj);
};

export const add_business_info_for_AI_content = (data) => {
  let requestObj = {
    path: "/api/business_info/add_business_info",
    method: "POST",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
  };

  requestObj["postData"] = data;
  return invokeApi(requestObj);
};

export const get_business_info_for_AI_content = (_id) => {
  let requestObj = {
    path: "/api/business_info/get_business_info/" + _id,
    method: "GET",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};

import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Loadable from "react-loadable";
import Loader from "./components/Loader/Loader";

// layouts
// import DashboardLayout from "./layouts/dashboard";
// import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
// import HeaderOnlyLayout from "./layouts/HeaderOnlyLayout";
// import MainEditorLayout from "./layouts/MainEditorLayout/Main.component";
const MainEditorLayout = Loadable({
  loader: () =>
    import(
      /*webpackChunkName: "rcs_MainEditorLayout"*/ "./layouts/MainEditorLayout/Main.component"
    ),
  loading() {
    return <Loader />;
  },
});
const HeaderOnlyLayout = Loadable({
  loader: () =>
    import(
      /*webpackChunkName: "rcs_HeaderOnlyLayout"*/ "./layouts/HeaderOnlyLayout"
    ),
  loading() {
    return <Loader />;
  },
});
const LogoOnlyLayout = Loadable({
  loader: () =>
    import(
      /*webpackChunkName: "rcs_LogoOnlyLayout"*/ "./layouts/LogoOnlyLayout"
    ),
  loading() {
    return <Loader />;
  },
});
const DashboardLayout = Loadable({
  loader: () =>
    import(/*webpackChunkName: "rcs_DashboardLayout"*/ "./layouts/dashboard"),
  loading() {
    return <Loader />;
  },
});
//
// import // BuilderRedirectController,
// AdminAuthController,
// Builder,
// ChooseTemplate,
// AddNewProject,
// ProjectList,
// ProjectPagesList,
// Login,
// Register,
// WebsiteSettings,
// PageSettings,
// ForgetPassword,
// Subscribers,
// HelpingCategories,
// HelpingArticles,
// SupportTickets,
// SupportTicketDetails,
// ProjectVersionList,
// ClientPortalController,
// "./pages/index";
const ClientPortalController = Loadable({
  loader: () =>
    import(
      /*webpackChunkName: "rcs_ClientPortalController"*/ "./pages/Client-Portal-Controller/ClientPortalController"
    ),
  loading() {
    return <Loader />;
  },
});
const ProjectVersionList = Loadable({
  loader: () =>
    import(
      /*webpackChunkName: "rcs_ProjectVersionList"*/ "./pages/Project-List/component/ProjectVersionList"
    ),
  loading() {
    return <Loader />;
  },
});
const SupportTicketDetails = Loadable({
  loader: () =>
    import(
      /*webpackChunkName: "rcs_SupportTicketDetails"*/ "./pages/Support-Ticket-Details/SupportTicketDetails"
    ),
  loading() {
    return <Loader />;
  },
});
const SupportTickets = Loadable({
  loader: () =>
    import(
      /*webpackChunkName: "rcs_SupportTickets"*/ "./pages/Support-Tickets/SupportTickets"
    ),
  loading() {
    return <Loader />;
  },
});
const HelpingArticles = Loadable({
  loader: () =>
    import(
      /*webpackChunkName: "rcs_HelpingArticles"*/ "./pages/Helping-Articles/HelpingArticles"
    ),
  loading() {
    return <Loader />;
  },
});
const HelpingCategories = Loadable({
  loader: () =>
    import(
      /*webpackChunkName: "rcs_HelpingCategories"*/ "./pages/Helping-Categories/HelpingCategories"
    ),
  loading() {
    return <Loader />;
  },
});
const Subscribers = Loadable({
  loader: () =>
    import(
      /*webpackChunkName: "rcs_Subscribers"*/ "./pages/Subscribers/Subscribers"
    ),
  loading() {
    return <Loader />;
  },
});
const ForgetPassword = Loadable({
  loader: () =>
    import(
      /*webpackChunkName: "rcs_ForgetPassword"*/ "./pages/Forget-Password/ForgetPassword"
    ),
  loading() {
    return <Loader />;
  },
});
const PageSettings = Loadable({
  loader: () =>
    import(
      /*webpackChunkName: "rcs_PageSettings"*/ "./pages/Page-Settings/PageSettings"
    ),
  loading() {
    return <Loader />;
  },
});
const WebsiteSettings = Loadable({
  loader: () =>
    import(
      /*webpackChunkName: "rcs_WebsiteSettings"*/ "./pages/Website-Settings/WebsiteSettings"
    ),
  loading() {
    return <Loader />;
  },
});
const Register = Loadable({
  loader: () => import(/*webpackChunkName: "rcs_Register"*/ "./pages/Register"),
  loading() {
    return <Loader />;
  },
});
const Login = Loadable({
  loader: () => import(/*webpackChunkName: "rcs_Login"*/ "./pages/Login"),
  loading() {
    return <Loader />;
  },
});
const ProjectPagesList = Loadable({
  loader: () =>
    import(
      /*webpackChunkName: "rcs_ProjectPagesList"*/ "./pages/Project-Pages-List/ProjectPagesList"
    ),
  loading() {
    return <Loader />;
  },
});
const BuilderRedirectController = Loadable({
  loader: () =>
    import(
      /*webpackChunkName: "rcs_BuilderRedirectController"*/ "./pages/Builder-Redirect-Controller/BuilderRedirectController"
    ),
  loading() {
    return <Loader />;
  },
});
const AdminAuthController = Loadable({
  loader: () =>
    import(
      /*webpackChunkName: "rcs_AdminAuthController"*/ "./pages/Admin-Auth-Controller/AdminAuthController"
    ),
  loading() {
    return <Loader />;
  },
});
const Builder = Loadable({
  loader: () =>
    import(/*webpackChunkName: "rcs_Builder"*/ "./pages/Builder/Builder"),
  loading() {
    return <Loader />;
  },
});
const ChooseTemplate = Loadable({
  loader: () =>
    import(
      /*webpackChunkName: "rcs_ChooseTemplate"*/ "./pages/Choose-Template/ChooseTemplate"
    ),
  loading() {
    return <Loader />;
  },
});
const AddNewProject = Loadable({
  loader: () =>
    import(
      /*webpackChunkName: "rcs_AddNewProject"*/ "./pages/Add-New-Project/AddNewProject"
    ),
  loading() {
    return <Loader />;
  },
});
const ProjectList = Loadable({
  loader: () =>
    import(
      /*webpackChunkName: "rcs_ProjectList"*/ "./pages/Project-List/ProjectList"
    ),
  loading() {
    return <Loader />;
  },
});
// import VersionsList from "./pages/Version-List/versionsList";
const VersionsList = Loadable({
  loader: () =>
    import(
      /*webpackChunkName: "rcs_VersionsList"*/ "./pages/Version-List/versionsList"
    ),
  loading() {
    return <Loader />;
  },
});
// import Page404 from "./pages/Page404";
const Page404 = Loadable({
  loader: () => import(/*webpackChunkName: "rcs_Page404"*/ "./pages/Page404"),
  loading() {
    return <Loader />;
  },
});
// import { ProjectVersionList } from "./pages/Project-List/component/ProjectVersionList";

// ----------------------------------------------------------------------
const Authentication = () => {
  let token = sessionStorage.getItem("token");
  const { hostname, pathname } = window.location;
  if (hostname == "localhost") {
    if (token) {
      return <Navigate to="/projects"> </Navigate>;
    } else {
      return <Navigate to="/login"> </Navigate>;
    }
  }
  return <Navigate to="/page404"> </Navigate>;
};

export default function Router() {
  return (
    <>
      <Routes>
        <Route
          path="/admin-auth-controller/:token"
          element={<AdminAuthController />}
        />
        <Route
          path="/client-auth-controller/:token/:project_id/:page_id"
          element={<ClientPortalController />}
        />
        <Route
          path="/user-auth-controller"
          element={<ClientPortalController />}
        />
        <Route element={<LogoOnlyLayout />}>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forget-password" element={<ForgetPassword />} />
        </Route>

        <Route element={<HeaderOnlyLayout />}>
          {/* helping articles */}
          <Route
            path="/helping-articles-categories"
            element={<HelpingCategories />}
          />

          <Route
            path="/helping-articles/:category_id"
            element={<HelpingArticles />}
          />

          <Route path="/choose-template" element={<ChooseTemplate />} />
          <Route path="/add-project" element={<AddNewProject />} />
          <Route path="/projects" element={<ProjectList />} />
          <Route path="/projects/:project_id" element={<ProjectPagesList />} />
          <Route
            path="/projects/:project_id/version"
            element={<ProjectVersionList />}
          />
          <Route
            path="/projects/:project_id/:page_id/version"
            element={<VersionsList />}
          />
          <Route
            path="/website-settings/:project_id"
            element={<WebsiteSettings />}
          />
          <Route
            path="/page-settings/:project_id/:page_id"
            element={<PageSettings />}
          />
          <Route path="/subscribers/:project_id" element={<Subscribers />} />
          <Route path="/support-tickets" element={<SupportTickets />} />
          <Route
            path="/support-tickets/:ticket_id"
            element={<SupportTicketDetails />}
          />
          {/* <Route
            path="/builder-redirect-controller"
            element={<BuilderRedirectController />}
          /> */}
        </Route>

        <Route element={<MainEditorLayout />}>
          <Route path="/builder/:project_id/:page_id" element={<Builder />} />
          <Route
            path="/builder-redirect-controller"
            element={<BuilderRedirectController />}
          />

          <Route path="/builder" element={<Builder />} />
        </Route>

        <Route element={<DashboardLayout />}>
          <Route path="/" element={<Authentication />} />
        </Route>

        <Route path="*" element={<Navigate to="/page404" />} />
        <Route path="/page404" element={<Page404 />} />
      </Routes>
    </>
  );
}

import { _dispatch_find_element_by_id_and_child } from "../../utils";

const _dispatch_update_styles_in_dom = ({ id, child, property, value }) => {
  if (!id) {
    console.error("Id is undefined", id);
    return;
  }

  let section = _dispatch_find_element_by_id_and_child(id, child);

  if (!section) {
    console.error("No section found", section);
    return;
  }
  if (value == "") {
    section.style[property] = "";
  } else {
    section.style[property] = value;
  }
};

const _dispatch_get_styles_from_dom = ({ id, child, property }) => {
  if (!id) {
    console.error("Id is undefined", id);
    return;
  }

  let section = _dispatch_find_element_by_id_and_child(id, child);

  if (!section) {
    console.error("No section found", section);
    return;
  }
  const { offsetHeight, offsetWidth } = section;
  section.setAttribute("imgheight", offsetHeight);
  section.setAttribute("imgwidth", offsetWidth);
  const value = section.style[property];
  return value;
};
const _dispatch_get_heightwidth_from_dom = (id, child) => {
  if (!id) {
    console.error("Id is undefined", id);
    return;
  }

  let section = _dispatch_find_element_by_id_and_child(id, child);

  const { offsetHeight, offsetWidth } = section;
  return { offsetHeight, offsetWidth };
};

const _dispatch_update_styles_by_element_in_dom = ({
  element,
  property,
  value,
}) => {
  if (!element) {
    console.error("No element found", element);
    return;
  }

  element.style[property] = value;
};

const _dispatch_get_styles_by_element_from_dom = ({ element, property }) => {
  if (!element) {
    console.error("No element found", element);
    return;
  }

  const value = element.style[property];
  return value;
};

function findNearestRelativeParent(element, ancestorTagName = "section") {
  // Start from the current element
  var currentElement = element;
  // Iterate up the DOM tree until we find a relative parent or reach the specified ancestor
  while (currentElement) {
    var computedStyle = window.getComputedStyle(currentElement);

    if (computedStyle.getPropertyValue("position") === "relative") {
      // If the current element has position: relative, return it
      return currentElement;
    }
    // Check inline style
    if (currentElement.style.position === "relative") {
      return currentElement;
    }
    if (currentElement.tagName.toLowerCase() !== ancestorTagName) {
      // Move to the parent element
      currentElement = currentElement.parentElement;
    } else {
      return null;
    }
  }
  // Return null if no relative parent is found or if the specified ancestor is reached
  return null;
}

const _element_has_absolute_position = (element) => {
  var positionProperties = {
    top: null,
    left: null,
    right: null,
    bottom: null,
    isTrue: false,
    element: null,
    topPX: "",
    leftPX: "",
    rightPX: "",
    bottomPX: "",
  };
  let currentElement = element;
  let numLevels = 0; // Counter for the number of levels traversed

  // Get the computed style of the element
  while (currentElement && numLevels < 3) {
    var computedStyle = window.getComputedStyle(currentElement);
    // Check if the computed style or inline style has position set to 'absolute'
    if (computedStyle.getPropertyValue("position") === "absolute") {
      let parent_element = findNearestRelativeParent(currentElement);

      positionProperties.isTrue = true;
      positionProperties.element = currentElement;
      // Retrieve top, left, right, bottom propertie
      positionProperties.topPX = parseFloat(
        computedStyle.getPropertyValue("top")
      );
      positionProperties.leftPX = parseFloat(
        computedStyle.getPropertyValue("left")
      );
      positionProperties.rightPX = parseFloat(
        computedStyle.getPropertyValue("right")
      );
      positionProperties.bottomPX = parseFloat(
        computedStyle.getPropertyValue("bottom")
      );

      // Convert pixel values to percentages
      positionProperties.top =
        (positionProperties.topPX / parent_element?.clientHeight) * 100;
      positionProperties.left =
        (positionProperties.leftPX / parent_element?.clientWidth) * 100;
      positionProperties.right =
        (positionProperties.rightPX / parent_element?.clientWidth) * 100;
      positionProperties.bottom =
        (positionProperties.bottomPX / parent_element?.clientHeight) * 100;

      break;
    } else if (currentElement.style.position === "absolute") {
      let parent_element = findNearestRelativeParent(currentElement);
      positionProperties.isTrue = true;
      positionProperties.element = currentElement;
      // Retrieve inline style properties
      positionProperties.topPX = parseFloat(currentElement.style.top);
      positionProperties.leftPX = parseFloat(currentElement.style.left);
      positionProperties.rightPX = parseFloat(currentElement.style.right);
      positionProperties.bottomPX = parseFloat(currentElement.style.bottom);

      // Convert pixel values to percentages
      positionProperties.top =
        (positionProperties.topPX / parent_element?.clientHeight) * 100;
      positionProperties.left =
        (positionProperties.leftPX / parent_element?.clientWidth) * 100;
      positionProperties.right =
        (positionProperties.rightPX / parent_element?.clientWidth) * 100;
      positionProperties.bottom =
        (positionProperties.bottomPX / parent_element?.clientHeight) * 100;

      break;
    }
    currentElement = currentElement.parentElement; // Move to the parent element
    numLevels++; // Increment the level counter
  }

  return positionProperties;
};

export {
  _dispatch_update_styles_in_dom,
  _dispatch_get_styles_from_dom,
  _dispatch_get_heightwidth_from_dom,
  _dispatch_update_styles_by_element_in_dom,
  _dispatch_get_styles_by_element_from_dom,
  _element_has_absolute_position,
};

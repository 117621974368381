function generate_random_string(characters, length) {
  var randomString = "";
  for (var i = 0; i < length; i++) {
    var index = Math.floor(Math.random() * characters.length);
    var character = characters[index];
    randomString = randomString + character;
  }
  return randomString;
}

function _generate_random_string(length = 10) {
  const string_secret_characters = "abcdefghijklmnopqrstuvwxyz";
  return generate_random_string(string_secret_characters, length);
}

function stringToArray(inputString) {
  // Remove any leading or trailing whitespace from the input string
  inputString = inputString.trim();
  inputString = inputString.replaceAll("&", ",");
  if (inputString.includes(",")) {
    const stringArray = inputString.split(",");
    const trimmedArray = stringArray.map((str) => str.trim());

    return trimmedArray;
  } else {
    {
      return [inputString];
    }
  }
}

const getIconsFromList = (list, value) => {
  const foundObject = list.find((obj) => obj.category === value);
  return foundObject ? foundObject.icon : null;
};

module.exports = { _generate_random_string, stringToArray, getIconsFromList };

import { invokeApi } from "../../bl_libs/invokeApi";

export const _create_user_design_page = (data) => {
  let requestObj = {
    // changethis
    path: "/api/page/add_page",
    method: "POST",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
  };

  requestObj["postData"] = data;
  return invokeApi(requestObj);
};

export const _get_user_design_details_page = (id) => {
  let requestObj = {
    path: "/api/page/detail_page/" + id,
    method: "GET",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};

export const _update_user_design_page = (id, data) => {
  let requestObj = {
    path: "/api/page/edit_page/" + id,
    method: "PUT",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
  };

  requestObj["postData"] = data;
  return invokeApi(requestObj);
};

export const _get_user_created_designs_page = () => {
  let requestObj = {
    path: "/api/page/list_page",
    method: "GET",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};

export const _update_page_meta_settings = (data) => {
  let requestObj = {
    path: "/api/page/update_page_meta_setting",
    method: "PUT",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
  };

  requestObj["postData"] = data;
  return invokeApi(requestObj);
};

export const _remove_user_design_page = (id) => {
  let requestObj = {
    path: "/api/page/delete_page/" + id,
    method: "DELETE",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
  };

  requestObj["postData"] = id;
  return invokeApi(requestObj);
};

export const _save_page_version = (data) => {
  let requestObj = {
    path: "/api/page/save_page_version",
    method: "POST",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
  };

  requestObj["postData"] = data;
  return invokeApi(requestObj);
};

export const _get_version_list = (id) => {
  let requestObj = {
    path: "/api/page/page_version_listing/" + id,
    method: "GET",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _use_page_version = (data) => {
  let requestObj = {
    path: "/api/page/use_page_version",
    method: "POST",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
  };

  requestObj["postData"] = data;
  return invokeApi(requestObj);
};

export const _delete_page_version = (id) => {
  let requestObj = {
    path: "/api/page/delete_page_version/" + id,
    method: "DELETE",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};

export const _get_version_count = (id) => {
  let requestObj = {
    path: "/api/page/page_version_count/" + id,
    method: "GET",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};

export const _update_page_version_name = (data) => {
  let requestObj = {
    path: "/api/page/update_page_version_name",
    method: "PUT",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
  };
  requestObj["postData"] = data;

  return invokeApi(requestObj);
};


export const _list_page_group = (_id) => {
  let requestObj = {
    path: "/api/page/list_page_group/" + _id,
    method: "GET",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};
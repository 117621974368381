import tinymce from "tinymce";
import {
  get_formatted_font_size,
  get_formatted_fonts,
} from "../components/Custom-Editor/CustomEditor";
let editorsList = [];
let elementEditor = null;
let topStyle = null;
let topValue = null;
let newTopValue = null;
let toolbar_Id = null;
let button = [];

export const InitializeTinyEditor = (
  setEditorMenuOpen,
  editorMenuOpen,
  setAiWriterModal,
  handleEditorPosition
) => {
  try {
    RemoveContentEditable();
    tinymce.init({
      selector: ".wb-editor-block", // change this value according to the HTML
      inline: true,
      menubar: false,
      branding: false,
      max_width: 430,
      plugins: ["link", "lists"],

      toolbar:
        "closeButton aiModalButton undo redo bold italic fontsize | fontfamily forecolor alignleft aligncenter alignright alignjustify outdent indent  underline strikethrough subscript link | superscript lineheight settingButton",
      // toolbar_mode: "wrap",

      setup: function (editor) {
        editor.ui.registry.addIcon(
          "iconStar",
          '<svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.3em" viewBox="0 0 56 56"><path fill="#FFBE55" d="M21.871 15.566c.281 0 .422-.164.469-.421c.797-3.75.75-3.891 4.664-4.688c.281-.047.445-.21.445-.469c0-.281-.164-.445-.445-.492c-3.914-.797-3.867-.937-4.664-4.664c-.047-.258-.188-.445-.469-.445c-.281 0-.422.187-.492.445c-.774 3.727-.727 3.867-4.664 4.664c-.258.047-.445.211-.445.492c0 .258.187.422.445.469c3.937.797 3.89.938 4.664 4.688c.07.257.21.421.492.421m19.36 8.274c.328 0 .515-.211.562-.516c.82-4.453.844-4.734 5.554-5.555c.305-.046.54-.257.54-.585c0-.329-.235-.516-.54-.586c-4.71-.797-4.734-1.078-5.554-5.532c-.047-.304-.234-.539-.562-.539c-.329 0-.54.235-.586.54c-.797 4.453-.82 4.734-5.532 5.53c-.328.071-.539.258-.539.587c0 .328.211.539.54.585c4.71.82 4.734 1.102 5.53 5.555c.047.305.258.516.587.516M9.027 30.566c.329 0 .516-.234.563-.539c.82-4.453.844-4.734 5.555-5.53c.304-.071.539-.259.539-.587c0-.328-.235-.539-.54-.586c-4.71-.82-4.734-1.101-5.554-5.555c-.047-.304-.235-.515-.563-.515c-.328 0-.539.21-.585.515c-.82 4.454-.82 4.735-5.532 5.555c-.328.047-.539.258-.539.586c0 .328.211.516.54.586c4.71.797 4.71 1.078 5.53 5.531c.047.305.258.54.586.54m40.008 20.04c1.008 1.007 2.695 1.007 3.61 0c.984-1.032.984-2.626 0-3.61l-22.266-22.36c-.984-.984-2.672-.984-3.61 0c-.984 1.032-.96 2.65 0 3.634ZM35.418 34.504l-6.867-6.89c-.422-.423-.54-.868-.14-1.29c.398-.375.843-.281 1.288.164l6.89 6.89ZM20.16 50.98c.422 0 .727-.305.774-.75c.773-6.258 1.078-6.422 7.43-7.454c.515-.093.82-.328.82-.797c0-.445-.305-.726-.727-.796c-6.398-1.22-6.75-1.196-7.523-7.453c-.047-.446-.352-.75-.774-.75c-.445 0-.75.304-.797.726c-.82 6.352-1.054 6.563-7.523 7.477c-.422.047-.727.351-.727.797c0 .445.305.703.727.796c6.469 1.242 6.68 1.242 7.523 7.5a.774.774 0 0 0 .797.703"/></svg>'
        );
        editor.ui.registry.addButton("closeButton", {
          icon: "close",
          tooltip: "Close",
          disabled: true,
          onAction: function () {
            let targetEditor = CheckEditorAlreadyExist(editor.id);
            if (targetEditor) {
              targetEditor.hide();
            } else {
              editorsList.push(editor);
              editor.hide();
            }
          },
        });
        editor.ui.registry.addButton("settingButton", {
          icon: "settings",
          tooltip: "Advance setting",
          disabled: true,
          onAction: function () {
            let targetEditor = CheckEditorAlreadyExist(editor.id);
            if (targetEditor) {
              targetEditor.hide();
            } else {
              editorsList.push(editor);
              editor.hide();
            }
            setTimeout(() => {
              if (!editorMenuOpen) {
                setEditorMenuOpen(true);
              }
            }, 100);
          },
        });
        editor.ui.registry.addButton("aiModalButton", {
          icon: "iconStar",
          tooltip: "Ai Text",
          disabled: true,
          onAction: function (e) {
            let elementPos = editor.targetElm.getBoundingClientRect();
            let x = elementPos.x,
              y =
                elementPos.y +
                document.getElementsByTagName("HTML")[0].scrollTop;

            let position = handleEditorPosition({ pageX: x });
            setAiWriterModal({
              open: true,
              pageX: position === "left" ? x + elementPos.width : x,
              pageY: y,
              html: editor.targetElm.innerHTML,
              element: editor.targetElm,
            });
            let targetEditor = CheckEditorAlreadyExist(editor.id);
            if (targetEditor) {
              targetEditor.hide();
            } else {
              editorsList.push(editor);
              editor.hide();
            }
          },
        });
        // initialize function
        editor.on("init", function (e) {
          editor.hide();
          editorsList.push(editor);
        });
      },
      font_size_formats: get_formatted_font_size(),
      font_family_formats: get_formatted_fonts(),
    });
  } catch (error) {
    console.error(error, "Error in tiny editor");
  }
};
export const RemoveContentEditable = () => {
  editorsList = [];
  // tinymce.remove();
  [...document.getElementsByClassName("wb-editor-block")].map((block) => {
    block.classList.remove("mce-content-body");
    block.classList.remove("mce-edit-focus");
    block.style.removeProperty("position");
    block.removeAttribute("id");
    block.removeAttribute("contenteditable");
    block.removeAttribute("spellcheck");
  });
};

export const ShowEditor = async (
  element,
  SaveActionToStack,
  checkHTMLExistorNotinStack
) => {
  CloseAllEditor(element);
  let targetEditor = CheckEditorAlreadyExist(element.getAttribute("id"));
  elementEditor = await targetEditor.container;
  setTimeout(() => {
    button = [...elementEditor?.querySelectorAll(".tox-tbtn")];
    button[button.length - 1]?.addEventListener("click", addListener);
  }, 50);
  if (targetEditor.hidden) {
    if (!checkHTMLExistorNotinStack()) {
      SaveActionToStack("inline editor");
    }
    // to hide the popup that comes from 3 dots when we reopen inline editor
    toolbar_Id = button[button.length - 1]?.getAttribute("aria-controls");
    let toolbarElement = document.getElementById(toolbar_Id);
    if (toolbarElement) {
      button[button.length - 1].click();
    }
    targetEditor.show();
  }
  handleInlineEditorPosition(element); //this function set the position if the editor if editors positon decreased from the 430 or inline editor goes in multiple lines
};

const handleInlineEditorPosition = async (element) => {
  let inlineEditorElement = elementEditor;
  if (!inlineEditorElement) {
    let targetEditor = CheckEditorAlreadyExist(element.getAttribute("id"));
    inlineEditorElement = await targetEditor.container;
  }
  let positions = inlineEditorElement.getBoundingClientRect();
  let elementPositions = element.getBoundingClientRect();
  if (
    positions.height > 43 &&
    checkDifferenceGreaterThan10(elementPositions.top, positions.bottom)
  ) {
    let value =
      inlineEditorElement.style.top.replace("px", "") -
      (positions.height - 43) +
      "px";
    setTimeout(() => {
      inlineEditorElement.style.setProperty("top", value, "important");
    }, 5);
  }
};

function checkDifferenceGreaterThan10(num1, num2) {
  if (Math.abs(num1 - num2) > 10) {
    return true;
  } else {
    return false;
  }
}

export const CloseAllEditor = (element) => {
  if (editorsList && editorsList.length > 0) {
    for (let i = 0; i < editorsList.length; i++) {
      if (element && element.getAttribute("id") === editorsList[i].id) {
        continue;
      }
      editorsList[i].hide();
    }
  }
  button[button.length - 1]?.removeEventListener("click", addListener);
};

function addListener() {
  setTimeout(() => {
    toolbar_Id = button[button.length - 1]?.getAttribute("aria-controls");
    let toolbarElement = document.getElementById(toolbar_Id);
    if (toolbarElement !== null) {
      topStyle = toolbarElement?.firstElementChild.style.top;
      let positions = toolbarElement?.firstElementChild.getBoundingClientRect();
      topValue = parseInt(topStyle, 10); // Parse the top value as an integer (removing "px" from the end)
      newTopValue = topValue - 160 - (positions.height - 125); // Subtract 125px from the top value + positions height - 125 is to keep up on the 3rd dot button if popup increase from 3 lines
      toolbarElement.firstElementChild.style.top = newTopValue + "px";
    }
  }, 20);
}
const CheckEditorAlreadyExist = (targetID) => {
  if (targetID && editorsList && editorsList.length > 0) {
    for (let i = 0; i < editorsList.length; i++) {
      if (editorsList[i].id === targetID) {
        return editorsList[i];
      }
    }
    return false;
  }
  return false;
};

const _dispatch_find_element_by_id_and_child = (id, child = 0) => {
  const element = document.getElementById(id);
  const section = element.getElementsByTagName("SECTION")[0];

  if (child === 0) {
    return section;
  }
  if (child === 1) {
    return section.firstElementChild;
  }
  if (child === 2) {
    return section.firstElementChild.firstElementChild;
  }
};

const array_list_elements = (targetElement) => {
  const list = [];

  for (let index = 0; index < targetElement.length; index++) {
    const element = targetElement[index];
    if (
      element.classList.contains("dropdown-menu") &&
      element.tagName === "UL" &&
      element.parentNode.tagName != "LI"
    ) {
      break;
    }
    // let children_loop = true;
    // for (let i = 0; i < element.children.length; i++) {
    //   if (
    //     element.tagName === "UL" &&
    //     element.parentNode.tagName === "LI"
    //   ) {
    //     break
    //   }
    // }

    if (targetElement[index].tagName == "I") continue;
    // if (element.children.length === 0) {
    //   data.push(element.innerText);
    // }

    const format = {
      main_element: element,
      sub_elements: array_list_elements(element.children),
      element_text: element.children.length === 0 ? element.innerText : "",
      class_name: element.className,
    };

    list.push(format);
  }
  return list;
};

const array_dropdown_list_elements = (targetElement) => {
  const list = [];

  for (let index = 0; index < targetElement.length; index++) {
    const element = targetElement[index];

    if (targetElement[index].tagName == "I") continue;
    // if (element.children.length === 0) {
    //   data.push(element.innerText);
    // }

    const format = {
      main_element: element,
      sub_elements: array_dropdown_list_elements(element.children),
      element_text: element.children.length === 0 ? element.innerText : "",
      class_name: element.className,
    };

    list.push(format);
  }
  return list;
};

const collect_list_data = (targetElement, data) => {
  for (let index = 0; index < targetElement.length; index++) {
    const element = targetElement[index];
    if (element.classList.contains("dropdown-menu")) {
      break;
    }
    let children_loop = true;
    for (let i = 0; i < element.children.length; i++) {
      if (
        element.children[i].tagName === "UL" &&
        element.children[i].parentNode.tagName === "LI"
      ) {
        children_loop = false;
      }
    }
    if (targetElement[index].tagName == "I") continue;
    if (element) {
      if (element.children.length === 0 || !children_loop) {
        data.push({
          text: element.innerText,
          // link: element.href
          //   ? element.href
          //       .replace(window.location.pathname, "")
          //       .replace(window.location.origin, "")
          //   : "",
          link: element.getAttribute("href"),
          target: element.target,
          classes: element.classList,
          children: element.children,
          show_dropDown_items: false,
        });
      }
    }
    if (children_loop) {
      collect_list_data(element.children, data);
    }
  }
  return data;
};
const collect_dropdown_list_data = (targetElement, data) => {
  for (let index = 0; index < targetElement.length; index++) {
    const element = targetElement[index];

    if (targetElement[index].tagName == "I") continue;
    if (element) {
      if (element.children.length === 0) {
        data.push({
          text: element.innerText,
          // link: element.href
          //   ? element.href
          //       .replace(window.location.pathname, "")
          //       .replace(window.location.origin, "")
          //   : "",
          link: element.getAttribute("href"),
          target: element.target,
          classes: element.classList,
          children: element.children,
        });
      }
    }
    collect_dropdown_list_data(element.children, data);
  }
  return data;
};

const _dispatch_find_lists_by_id_and_child = (id) => {
  const element = document.getElementById(id);
  let list = [];
  let data = [];

  let show_menu = true;

  const ul_list = element.getElementsByTagName("UL");

  if (element.getElementsByTagName("nav").length === 0) {
    show_menu = false;
    return { show_menu };
  }
  if (ul_list) {
    list = array_list_elements(ul_list);
  }
  data = collect_list_data(ul_list, data);

  return { list, data, show_menu };
};
const _dispatch_find_nav_list_and_child = (ul_list) => {
  let list = [];
  let data = [];

  let show_menu = true;

  // let ul_list = element.getElementsByTagName("UL");
  // if (element.tagName != "NAV") {
  //   show_menu = false;
  //   return { show_menu };
  // }

  // let filteredList = [];
  // for (let index = 0; index < ul_list.length; index++) {
  //   if (ul_list[index].parentNode.tagName !== "LI") {
  //     filteredList.push(ul_list[index]);
  //   }
  // }
  // ul_list = filteredList;
  if (ul_list) {
    list = array_list_elements(ul_list);
    console.log(list, "ListData");
  }

  data = collect_list_data(ul_list, data);

  return { list, data, show_menu };
};

const _dispatch_find_nav_dropdown_list_and_child = (ul_list) => {
  let list = [];
  let data = [];

  let show_menu = true;

  // let ul_list = element.getElementsByTagName("UL");
  // if (element.tagName != "NAV") {
  //   show_menu = false;
  //   return { show_menu };
  // }

  // let filteredList = [];
  // for (let index = 0; index < ul_list.length; index++) {
  //   if (ul_list[index].parentNode.tagName !== "LI") {
  //     filteredList.push(ul_list[index]);
  //   }
  // }
  // ul_list = filteredList;
  if (ul_list) {
    list = array_dropdown_list_elements(ul_list);
    console.log(list, "ListData");
  }

  data = collect_dropdown_list_data(ul_list, data);

  return { list, data, show_menu };
};

const _dispatch_find_i_frames_by_id = (id) => {
  const element = document.getElementById(id);

  let list = [];

  let show_menu = true;
  let frame = element.getElementsByClassName("wb-iframe");

  if (frame.length === 0) {
    show_menu = false;
    return { show_menu };
  }

  for (let index = 0; index < frame.length; index++) {
    const element = frame[index];
    const value = frame[index].innerHTML;
    const height = frame[index].clientHeight;
    list.push({ element, value: value ? value.trim() : "", height: height });
  }

  return { list, frame, show_menu };
};

const _dispatch_find_form_by_id = (id) => {
  const element = document.getElementById(id);
  if (element) {
    let list = [];

    let show_menu = true;
    let form = element.getElementsByTagName("form");

    if (form.length === 0) return { form: form, status: false };

    const first = form[0][0];

    return { form: form, status: true };
  } else {
    return { form: null, status: false };
  }
};
const _dispatch_find_plan_card_by_id = (id) => {
  const element = document.getElementById(id);
  if (element) {
    let payment_plan_card = element.getElementsByClassName("wb-plan-card");
    if (payment_plan_card.length === 0)
      return { payment_plan_card: payment_plan_card, status: false };
    return { payment_plan_card: payment_plan_card, status: true };
  } else {
    return { payment_plan_card: null, status: false };
  }
};

const _dispatch_get_dataset_by_settingId = (id) => {
  let sectionContainer = document.getElementById(id);
  let section = sectionContainer.getElementsByTagName("section");

  return section.item(0).dataset;
};
const _dispatch_handle_href_target = (element, action) => {
  console.log(action, "ACTION");
  if (!element) {
    console.error("No element!");
    return;
  }
  if (action == "add") {
    element.setAttribute("target", "_blank");
  }
  if (action == "remove") {
    element.removeAttribute("target");
  }
};

const _find_element_has_class = (element, class_name) => {
  let currentElement = element;

  while (currentElement && currentElement.parentNode) {
    if (
      currentElement.classList &&
      currentElement.classList.contains(class_name)
    ) {
      return currentElement;
    } else {
      currentElement = currentElement.parentNode;
    }
  }

  return false;
};
const _find_parent_row_of_element = (element) => {
  let currentElement = element;

  while (currentElement && currentElement.parentNode) {
    if (currentElement.classList && currentElement.classList.contains("row")) {
      return currentElement;
    } else {
      currentElement = currentElement.parentNode;
    }
  }

  return false;
};
const _find_is_editor_element = (element) => {
  let currentElement = element;
  while (
    currentElement &&
    currentElement.parentNode &&
    currentElement.tagName !== "SECTION"
  ) {
    if (
      currentElement.classList &&
      currentElement.classList.contains("wb-editor-block")
    ) {
      return currentElement;
    }
    currentElement = currentElement.parentNode;
  }

  return false;
};
const _find_element_has_tag = (element, tag_name) => {
  let currentElement = element;
  while (currentElement && currentElement.tagName !== "SECTION") {
    if (currentElement.tagName == tag_name) {
      return currentElement;
    }
    currentElement = currentElement.parentNode;
  }

  return false;
};
const _find_section_of_element = (element) => {
  let currentElement = element;

  if (element.classList) {
    if (element.classList.contains("edit-able-section")) {
      return element.children[0];
    } else if (element.classList.contains("break-able-section")) {
      return element.children[0];
    }
  }

  while (currentElement && currentElement.parentNode) {
    if (currentElement && currentElement.tagName == "SECTION") {
      return currentElement.parentNode.parentNode;
    } else {
      currentElement = currentElement.parentNode;
    }
  }
  return false;
};

const _dispatch_find_section_type_by_id = (id, type) => {
  const element = document.getElementById(id);
  if (element) {
    let SectionElement = element.getElementsByClassName(type);
    if (SectionElement.length === 0)
      return { SectionElement: SectionElement, status: false };
    return { SectionElement: SectionElement, status: true };
  } else {
    return { SectionElement: null, status: false };
  }
};
export {
  _dispatch_get_dataset_by_settingId,
  _dispatch_find_element_by_id_and_child,
  _dispatch_find_lists_by_id_and_child,
  _dispatch_find_i_frames_by_id,
  _dispatch_find_form_by_id,
  _dispatch_find_plan_card_by_id,
  _dispatch_handle_href_target,
  _find_parent_row_of_element,
  _find_section_of_element,
  _find_is_editor_element,
  _find_element_has_tag,
  _find_element_has_class,
  _dispatch_find_nav_list_and_child,
  _dispatch_find_nav_dropdown_list_and_child,
  _dispatch_find_section_type_by_id,
};

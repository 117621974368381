import React from "react";
import { CircularProgress } from "@mui/material";

function Loader({ labe_value = null, forTab = false, _height = null }) {
  return (
    <div className={forTab ? "" : "centered"}>
      {/* <CircularProgress sx={{ marginLeft: 2 }} /> */}
      {/* <div class="loader"></div> */}
      <div class="loader" style={{ height: _height ? _height : "" }}>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div className="col-12">{labe_value && <label>{labe_value}</label>}</div>
    </div>
  );
}

export default Loader;
